@font-face {
  font-family: 'GothamMedium';
  src: url('./assets/fonts/GothamMedium.otf')
}

@font-face {
  font-family: 'GothamBold';
  src: url('./assets/fonts/GothamBold.otf')
}

* {
  font-family: GothamMedium;  
}

/* ANTD OVERRIDES */
.ant-table-wrapper.pagination-left .ant-table-pagination {
  float: left;
}
/* REACT PIVOT TABLE OVERRIDES */
.pivot-table.hide-config .pvtRenderers,
.pivot-table.hide-config .pvtAxisContainer,
.pivot-table.hide-aggregator .pvtVals,
.pivot-table.hide-totals .pvtTotal,
.pivot-table.hide-totals .pvtTotalLabel,
.pivot-table.hide-totals .pvtGrandTotal,
.pivot-table.hide-row-totals .pvtGrandTotal,
.pivot-table.hide-row-totals tr td.pvtTotal,
.pivot-table.hide-row-totals thead .pvtTotalLabel,
.pivot-table.hide-column-totals .pvtGrandTotal,
.pivot-table.hide-column-totals tr td.pvtTotal,
.pivot-table.hide-column-totals .pvtTotalLabel {
  display: none;
}
.pivot-table.hide-row-totals .pvtOutput tr:last-child td.pvtTotal  {
  display: table-cell;
}
.pivot-table.hide-column-totals .pvtOutput thead .pvtTotalLabel, 
.pivot-table.hide-column-totals .pvtOutput tr td:last-child.pvtTotal {
  display: table-cell;
}

.pvtUi {
  width: 99%;
}
.pvtVertList {
  width: 15%;
}
.pvtTable {
  width: 100%;
}

/* ISOMORPHIC OVERRIDES */
.isomorphicSidebar i {
  font-size: 16px;
  margin-bottom: 3px !important;
}

/* CUSTOM CSS */
.box {
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  overflow-wrap: break-word;
}

.box.transparent {
  background: transparent;
}
.label {
  font-weight: bold;
  margin-right: 10px;
}
.data-group {
  padding-bottom: 5px;
}

.data-block {
  padding: 10px;
}

.ant-btn-primary, .ant-btn-primary:focus {
  background-color: #00769A;
  border-color: #00769A;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00769A;
  border-color: #00769A;
}
.ant-switch {
  background-color: #00769A!important;
}

.google-chart g text {
  cursor: pointer;
}

iframe {
  border: 0px;
}