.validation-container {
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.65);
}



.validation-list li {
  height: 30px;
  text-align: left;
}

.green {
  font-weight: bold;
  color: #00b500;
}

.progress-title {
  height: 20px;
  margin-bottom: 10px;
}

.progress {
  background-color: #828282;
  border-radius: 13px;
  margin-left: 5px;
  margin-right: 5px;
}

.progress-bar {
  height: 12px;
  border-radius: 10px;
}

.progress-result {
  height: 30px;
  margin-bottom: 2px;
}