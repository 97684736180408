@font-face {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: normal;
  src: url(/../../../public/fonts/GothamMedium.otf) format('otf');
}

@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: normal;
  src: url(/../../../public/fonts/GothamBold.otf) format('otf');
}

/********** Add Your Global CSS Here **********/

h1, h2, h3, h4, h5, h6, a, p, li, input, textarea, span, div, html, body, html a {
  margin-bottom: 0;
  font-size: 12px !important;
  font-family: GothamMedium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y, .scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x, .scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow:after, .ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow:after, .ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow:after, .ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow:after, .ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow:after, .ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

.ant-spin-dot {
  font-size: 18px !important;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after, html[dir='rtl'] .has-warning.has-feedback:after, html[dir='rtl'] .has-error.has-feedback:after, html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button+button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn>.anticon+span, html[dir='rtl'] .ant-btn>span+.anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body>.anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button+button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl'] .ant-notification-notice-closable .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl'] .ant-notification-notice-with-icon .ant-notification-notice-message, html[dir='rtl'] .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}
.Signin_container__1hkS7 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.Signin_content__2MeGJ {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.Signin_logo__1nBVM {
  text-align: center;
  padding: 20px 30px 20px 30px;
}

.Signin_mfaInput__26b3r {
  display: flex;
  color: black;
  font-weight: 400;
  align-items: left !important;
  justify-content: left !important;
}
.validation-container {
  border: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.65);
}



.validation-list li {
  height: 30px;
  text-align: left;
}

.green {
  font-weight: bold;
  color: #00b500;
}

.progress-title {
  height: 20px;
  margin-bottom: 10px;
}

.progress {
  background-color: #828282;
  border-radius: 13px;
  margin-left: 5px;
  margin-right: 5px;
}

.progress-bar {
  height: 12px;
  border-radius: 10px;
}

.progress-result {
  height: 30px;
  margin-bottom: 2px;
}
@font-face {
  font-family: 'GothamMedium';
  src: url(/static/media/GothamMedium.e19c20e9.otf)
}

@font-face {
  font-family: 'GothamBold';
  src: url(/static/media/GothamBold.722c5f89.otf)
}

* {
  font-family: GothamMedium;  
}

/* ANTD OVERRIDES */
.ant-table-wrapper.pagination-left .ant-table-pagination {
  float: left;
}
/* REACT PIVOT TABLE OVERRIDES */
.pivot-table.hide-config .pvtRenderers,
.pivot-table.hide-config .pvtAxisContainer,
.pivot-table.hide-aggregator .pvtVals,
.pivot-table.hide-totals .pvtTotal,
.pivot-table.hide-totals .pvtTotalLabel,
.pivot-table.hide-totals .pvtGrandTotal,
.pivot-table.hide-row-totals .pvtGrandTotal,
.pivot-table.hide-row-totals tr td.pvtTotal,
.pivot-table.hide-row-totals thead .pvtTotalLabel,
.pivot-table.hide-column-totals .pvtGrandTotal,
.pivot-table.hide-column-totals tr td.pvtTotal,
.pivot-table.hide-column-totals .pvtTotalLabel {
  display: none;
}
.pivot-table.hide-row-totals .pvtOutput tr:last-child td.pvtTotal  {
  display: table-cell;
}
.pivot-table.hide-column-totals .pvtOutput thead .pvtTotalLabel, 
.pivot-table.hide-column-totals .pvtOutput tr td:last-child.pvtTotal {
  display: table-cell;
}

.pvtUi {
  width: 99%;
}
.pvtVertList {
  width: 15%;
}
.pvtTable {
  width: 100%;
}

/* ISOMORPHIC OVERRIDES */
.isomorphicSidebar i {
  font-size: 16px;
  margin-bottom: 3px !important;
}

/* CUSTOM CSS */
.box {
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  overflow-wrap: break-word;
}

.box.transparent {
  background: transparent;
}
.label {
  font-weight: bold;
  margin-right: 10px;
}
.data-group {
  padding-bottom: 5px;
}

.data-block {
  padding: 10px;
}

.ant-btn-primary, .ant-btn-primary:focus {
  background-color: #00769A;
  border-color: #00769A;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00769A;
  border-color: #00769A;
}
.ant-switch {
  background-color: #00769A!important;
}

.google-chart g text {
  cursor: pointer;
}

iframe {
  border: 0px;
}
