.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.content {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.logo {
  text-align: center;
  padding: 20px 30px 20px 30px;
}

.mfaInput {
  display: flex;
  color: black;
  font-weight: 400;
  align-items: left !important;
  justify-content: left !important;
}